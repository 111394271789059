import React from 'react';
import 'animate.css';
import logosynergia from '../../assets/images/QuienesSomos.svg';

const QuienesSomos = () => {
  return (
    <div className="bg-white py-16 sm:py-24 quienes-somos" id="quienes-somos"> {/* Clase y ID añadidos aquí */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row-reverse items-center justify-between">
          <div className="w-full md:w-1/2 flex justify-center mt-10 md:mt-0 animate__animated animate__slideInUp">
            <img src={logosynergia} alt="Synergia Consulting" className="h-32 w-auto" />
          </div>
          <div className="w-full md:w-1/2 md:pr-12">
            <p className="text-base font-semibold text-[#7A121A] tracking-wide uppercase text-left">¡Somos Uno!</p>
            <h2 className="mt-1 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl text-left">
              QUIENES SOMOS
            </h2>
            <p className="mt-5 text-xl text-gray-500 text-justify">
              Synergia Consulting nace con el ﬁrme objetivo de brindarle soluciones integrales a su empresa, reuniendo a un talentoso equipo de profesionales que, con su experiencia, está preparado para trabajar en conjunto y diseñar soluciones a la medida, asegurándonos de que prevalezca la calidez humana, el respeto, la integridad y la calidad en el servicio. Trabajamos en equipo para alcanzar su satisfacción y apoyarle en el crecimiento y mejora de su empresa en la gestión del talento humano, contabilidad, legal, operaciones y logística. ¡Somos Synergia, somos uno!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuienesSomos;
