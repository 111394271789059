import React, { useState } from 'react';
import { RiMenuUnfold2Fill } from "react-icons/ri";
import logosynergia from '../assets/images/logosynergia.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-[#7A121A] sticky top-0 z-50 border-b-2 border-white">
      <nav className="max-w-7xl mx-auto flex items-center justify-between py-4 px-6 lg:px-8">
        <div className="flex items-center">
          <a href="/">
            <img src={logosynergia} alt="Synergia Logo" className="h-8 w-auto" />
          </a>
        </div>
        <div className="hidden md:flex flex-1 justify-center space-x-10">
          <a href="/" className="text-base font-medium text-white hover:text-gray-300">Inicio</a>
          <a href="/#quienes-somos" className="text-base font-medium text-white hover:text-gray-300">Quienes Somos</a>
          <a href="/vacantes" className="text-base font-medium text-white hover:text-gray-300">Vacantes</a>
          <a href="/#contactenos" className="text-base font-medium text-white hover:text-gray-300">Contáctenos</a>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          <span className="text-base font-medium text-white hover:text-gray-300 cursor-pointer">Registrarme</span>
          <button className="text-[#7A121A] bg-gray-200 hover:bg-[#b91c1c] hover:text-white px-4 py-2 rounded-md">
            Ingresar
          </button>
        </div>
        <div className="flex md:hidden items-center">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            <RiMenuUnfold2Fill className="h-8 w-8" />
          </button>
        </div>
      </nav>
      {isOpen && (
        <div className="md:hidden bg-[#7A121A]">
          <div className="flex flex-col items-center space-y-4 py-4">
            <a href="/" className="text-base font-medium text-white hover:text-gray-300">Inicio</a>
            <a href="/#quienes-somos" className="text-base font-medium text-white hover:text-gray-300">Quienes Somos</a>
            <a href="/Vacantes" className="text-base font-medium text-white hover:text-gray-300">Vacantes</a>
            <a href="/#contactenos" className="text-base font-medium text-white hover:text-gray-300">Contáctenos</a>
            <span className="text-base font-medium text-white hover:text-gray-300 cursor-pointer">Registrarme</span>
            <button className="text-[#7A121A] bg-gray-200 hover:bg-[#b91c1c] hover:text-white px-4 py-2 rounded-md">
              Ingresar
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
