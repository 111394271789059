import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Vacantes from './pages/Vacantes';
import ProfileForm from './components/perfil/ProfileForm';
import UserProfile from './components/perfil/UserProfile';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/vacantes" element={<Vacantes />} />
          <Route path="/form-postular" element={<ProfileForm />} />
          <Route path="/user-postular" element={<UserProfile />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
