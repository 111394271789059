import React from 'react';
import bgContacto from '../../assets/images/Talento-Humano.jpg';

const Contactos = () => {
  return (
    <div
      id="contactenos" // ID añadido aquí
      className="relative bg-cover bg-center py-16 sm:py-24 contactenos" // Clase añadida aquí
      style={{
        backgroundImage: `url(${bgContacto})`,
      }}
    >
      <div className="absolute inset-0 bg-[#7A121A] opacity-75"></div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center text-white">
          <h1 className="text-3xl font-bold mb-8 animate__animated animate__slideInUp">CONTACTOS</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-2xl font-bold">Carolina Chavarría Z.</h2>
              <p>Directora Ejecutiva</p>
              <p>Móvil: (506) 7162 - 3700</p>
              <p>caro@synergiaconsultingcr.com</p>
            </div>
            <div>
              <h2 className="text-2xl font-bold">Keyla Brenes.</h2>
              <p>Generalistas de RRHH</p>
              <p>Móvil: (506) 7162 - 3700</p>
              <p>keyla@synergiaconsultingcr.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactos;
