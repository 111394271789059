import React from 'react';
import bgVacantes from '../assets/images/Gestion-Financiera.jpg'; // Asumiendo que tienes una imagen adecuada

const Vacantes = () => {
    return (
      <div
        className="relative bg-cover bg-center text-white"
        style={{
          backgroundImage: `url(${bgVacantes})`,
          minHeight: '70vh',
        }}
      >
        <div className="absolute inset-0 bg-[#7A121A] opacity-80"></div> {/* Aumenté la opacidad para mejorar la legibilidad */}
        <div className="flex justify-center items-center h-full">
          <div className="text-center px-4">
            <h1 className="text-4xl font-bold mb-4 animate__animated animate__fadeInDown">
              Próximamente
            </h1>
            <p className="text-xl font-semibold animate__animated animate__fadeIn opacity-90">
              Estamos trabajando para ofrecerte nuevas oportunidades.
            </p>
            <p className="mt-2 text-lg animate__animated animate__fadeIn animate__delay-1s">
              Mantente atento a las próximas vacantes y oportunidades de empleo.
            </p>
          </div>
        </div>
      </div>
    );
  };
  
  export default Vacantes;